.score {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--misty-rose-20);
  padding: 2em;
  top: 10%;
  border-radius: 20px;
  &_text {
    font-size: 6em;
  }
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 0.5em 0;
}

.bpm_text {
  padding-bottom: 0.5rem;
  font-size: 3rem;
}
