.config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  &_form {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
  .error-message {
    display: flex;
    justify-content: center;
    color: var(--outrageous-orange-darken-20);
  }
}
